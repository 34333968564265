import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CacheKeys } from 'src/app/configs/cacheKeys';
import { AppRoutes } from 'src/app/configs/routes';
import { UserDto } from 'src/app/shared/models/userDto';
import { NavigationItem, UserUI } from 'src/app/shared/models/UserUI';
import { Api } from '../../configs/config';
import { MessageService } from './message.service';

declare let gtag;

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public User_UI$: Subject<UserUI> = new Subject<UserUI>();

	constructor(private http: HttpClient, private messageService: MessageService, private router: Router) {}

	/**
	 * Login to SportsEye
	 * @param username
	 * @param pwd
	 */
	login(username: string, pwd: string): Observable<any> {
		let device: string = 'dev' + Date.now();

		return this.http.post<any>(Api.authApiUrl, { UserName: username, Password: pwd, Device: device }).pipe(
			tap((res) => {
				const orgId = res.userInfo.organisationId;
				gtag('set', 'USER_ID', orgId);
			})
		);
	}

	/**
	 * Request a password reset email be sent
	 * @param userName
	 */
	requestResetPassword(userName: string) {
		return this.http.post<any>(Api.passwordRequestResetUrl, { UserName: userName });
	}

	/**
	 * Submit password reset with new password
	 * @param resetPwdToken
	 * @param newPwd
	 */
	resetPassword(resetPwdToken: string, newPwd: string) {
		return this.http.post<any>(Api.passwordResetUrl, { ResetPasswordToken: resetPwdToken, NewPassword: newPwd });
	}

	/** Perform a refresh token request */
	refreshToken(): Observable<any> {
		let token = this.getToken();
		let refreshToken = this.getRefreshToken();
		return this.http.post<any>(Api.refreshTokenApiUrl, { Token: token, RefreshToken: refreshToken });
	}

	/** Logout of SportsEye */
	logOut() {
		let token = this.getToken();

		this.http.post<any>(Api.logoutUrl, { Token: token }).subscribe(
			_ => {
				this.messageService.isLoggedIn(false);
				this.router.navigate(['/login']);
			},
			(err) => {
				this.handleLogoutErr(err);
			}
		);
		this.clearSessionStorage();
	}

	handleLogoutErr(err: any) {
		this.messageService.isLoggedIn(false);
		this.clearSessionStorage();
		this.router.navigate(['/login']);
	}

	/** Get the current storage type for user information */
	private userStorageType(): Storage {
		return this.getRememberMe() ? localStorage : sessionStorage;
	}

	/**
	 * Retrieve an item reference from storage
	 * @param itemName
	 */
	private getItemFromStorage(itemName: string, defaultValue: any = '') {
		return this.userStorageType().getItem(itemName) ?? defaultValue;
	}

	// tokens
	getToken(): string {
		return this.getItemFromStorage(CacheKeys.Token);
	}
	setToken(token: string) {
		this.userStorageType().setItem(CacheKeys.Token, token);
	}
	getRefreshToken(): string {
		return this.getItemFromStorage(CacheKeys.RefreshToken);
	}
	setRefreshToken(refToken: string) {
		this.userStorageType().setItem(CacheKeys.RefreshToken, refToken);
	}

	// user information
	getUserName(): string {
		return this.getItemFromStorage(CacheKeys.UserName);
	}
	getFullName(): string {
		return this.getItemFromStorage(CacheKeys.UserFullName);
	}
	getFirstName(): string {
		return this.getItemFromStorage(CacheKeys.UserFirstName);
	}
	getUserOrganisationId(): number {
		return this.getItemFromStorage(CacheKeys.OrganisationId, 0);
	}

	getUserInfo(): UserDto {
		const user = this.getItemFromStorage(CacheKeys.UserInfo);
		if (user != '') return JSON.parse(user);
		else return {} as UserDto;
	}

	// authentication and platform
	isUserSportsEyeAdmin(): boolean {
		return this.getUserRole() === 'SportsEye Admin';
	}
	isUserOrganisationAdmin(): boolean {
		return this.getUserRole() === 'Organisation Admin';
	}
	getUserRole(): string {
		return this.getItemFromStorage(CacheKeys.UserRole);
	}
	getPlatform(): string | null {
		return sessionStorage.getItem(CacheKeys.Platform) || null;
	}
	isUserLoggedIn(): boolean {
		if (
			this.userStorageType().getItem(CacheKeys.IsUserLoggedIn) &&
			this.userStorageType().getItem(CacheKeys.IsUserLoggedIn) == 'true'
		)
			return true;
		else return false;
	}
	setIsLoggedIn(isLoggedIn: string) {
		if (isLoggedIn) this.userStorageType().setItem(CacheKeys.IsUserLoggedIn, isLoggedIn);
		else this.userStorageType().removeItem(CacheKeys.IsUserLoggedIn);
	}

	getFeaturesFlag(): number {
		return this.getItemFromStorage(CacheKeys.FeaturesFlag, 0);
	}

	// platform cached values
	getEnums(): string | null {
		return sessionStorage.getItem(CacheKeys.Enums) || null;
	}
	setEnums(enums: string) {
		sessionStorage.setItem(CacheKeys.Enums, enums);
	}

	// convenience configuration data stored locally
	getNavTiles(): NavigationItem[] {
		let tiles: string = this.getItemFromStorage(CacheKeys.NavTiles);

		if (tiles != '') return JSON.parse(tiles);
		else return [] as NavigationItem[];
	}

	getUserMenu(): NavigationItem[] {
		let res: string = this.getItemFromStorage(CacheKeys.UserMenu);

		if (res != '') return JSON.parse(res);
		else return [] as NavigationItem[];
	}

	getTopMenu(): NavigationItem[] {
		let res: string = this.getItemFromStorage(CacheKeys.TopMenu);

		if (res != '') return JSON.parse(res);
		else return [] as NavigationItem[];
	}

	getRememberMe(): boolean {
		if (localStorage.getItem(CacheKeys.RememberMe) === null || localStorage.getItem(CacheKeys.RememberMe) != 'true')
			return false;
		else return true;
	}

	setRememberMe(remeberMe: string) {
		if (remeberMe == 'true') localStorage.setItem(CacheKeys.RememberMe, remeberMe);
		else localStorage.removeItem(CacheKeys.RememberMe);
	}

	/** Clear Session Storage */
	clearSessionStorage() {
		localStorage.setItem(CacheKeys.IsUserLoggedIn, 'false');

		this.userStorageType().removeItem(CacheKeys.UserName);
		this.userStorageType().removeItem(CacheKeys.UserFullName);
		this.userStorageType().removeItem(CacheKeys.UserFirstName);
		this.userStorageType().removeItem(CacheKeys.Token);
		this.userStorageType().removeItem(CacheKeys.RefreshToken);
		this.userStorageType().removeItem(CacheKeys.Enums);
		this.userStorageType().removeItem(CacheKeys.IsUserLoggedIn);
		this.userStorageType().removeItem(CacheKeys.UserRole);
		this.userStorageType().removeItem(CacheKeys.OrganisationId);
		this.userStorageType().removeItem(CacheKeys.UserInfo);
		this.userStorageType().removeItem(CacheKeys.Platform);
		this.userStorageType().removeItem(CacheKeys.NavTiles);
		this.userStorageType().removeItem(CacheKeys.UserMenu);
		this.userStorageType().removeItem(CacheKeys.TopMenu);
		this.userStorageType().removeItem(CacheKeys.FeaturesFlag);
	}

	setSessionStorageFromAuthResponse(data: any, rememberMe: boolean) {
		if (rememberMe) {
			this.setRememberMe('true');
			localStorage.setItem(CacheKeys.IsUserLoggedIn, 'true');
			localStorage.setItem(CacheKeys.UserName, data.userInfo.userName);
			localStorage.setItem(CacheKeys.IsUserLoggedIn, 'true');
			localStorage.setItem(CacheKeys.UserFullName, data.userInfo.firstName + ' ' + data.userInfo.lastName);
			localStorage.setItem(CacheKeys.UserFirstName, data.userInfo.firstName);

			localStorage.setItem(CacheKeys.Token, data.token);
			localStorage.setItem(CacheKeys.RefreshToken, data.refreshToken);
			localStorage.setItem(CacheKeys.UserRole, data.userInfo.role);
			localStorage.setItem(CacheKeys.OrganisationId, data.userInfo.organisationId);
			localStorage.setItem(CacheKeys.Platform, data.userInfo.organisationPlatform);
			localStorage.setItem(CacheKeys.Country, data.userInfo.organisationCountry);
			localStorage.setItem(CacheKeys.FeaturesFlag, data.userInfo.featuresFlag);

			localStorage.setItem(CacheKeys.UserInfo, JSON.stringify(data.userInfo));

			localStorage.setItem(
				CacheKeys.NavTiles,
				JSON.stringify(
					this.sanitizeRoutes(data.userUI.navigationItems, data.userInfo.organisationId, CacheKeys.NavTiles)
				)
			);

			localStorage.setItem(
				CacheKeys.UserMenu,
				JSON.stringify(
					this.sanitizeRoutes(data.userUI.navigationItems, data.userInfo.organisationId, CacheKeys.UserMenu)
				)
			);

			localStorage.setItem(
				CacheKeys.TopMenu,
				JSON.stringify(
					this.sanitizeRoutes(data.userUI.navigationItems, data.userInfo.organisationId, CacheKeys.TopMenu)
				)
			);
		} else {
			this.setRememberMe('false');
			sessionStorage.setItem(CacheKeys.IsUserLoggedIn, 'true');
			sessionStorage.setItem(CacheKeys.UserName, data.userInfo.userName);
			sessionStorage.setItem(CacheKeys.IsUserLoggedIn, 'true');
			sessionStorage.setItem(CacheKeys.UserFullName, data.userInfo.firstName + ' ' + data.userInfo.lastName);
			sessionStorage.setItem(CacheKeys.UserFirstName, data.userInfo.firstName);

			sessionStorage.setItem(CacheKeys.Token, data.token);
			sessionStorage.setItem(CacheKeys.RefreshToken, data.refreshToken);
			sessionStorage.setItem(CacheKeys.UserRole, data.userInfo.role);
			sessionStorage.setItem(CacheKeys.OrganisationId, data.userInfo.organisationId);
			sessionStorage.setItem(CacheKeys.Platform, data.userInfo.organisationPlatform);
			sessionStorage.setItem(CacheKeys.Country, data.userInfo.organisationCountry);
			sessionStorage.setItem(CacheKeys.FeaturesFlag, data.userInfo.featuresFlag);

			sessionStorage.setItem(CacheKeys.UserInfo, JSON.stringify(data.userInfo));

			//-- Store Tile Items after Filtering from navigatioItems and replace variables and converting objects into string
			sessionStorage.setItem(
				CacheKeys.NavTiles,
				JSON.stringify(
					this.sanitizeRoutes(data.userUI.navigationItems, data.userInfo.organisationId, CacheKeys.NavTiles)
				)
			);

			sessionStorage.setItem(
				CacheKeys.UserMenu,
				JSON.stringify(
					this.sanitizeRoutes(data.userUI.navigationItems, data.userInfo.organisationId, CacheKeys.UserMenu)
				)
			);

			sessionStorage.setItem(
				CacheKeys.TopMenu,
				JSON.stringify(
					this.sanitizeRoutes(data.userUI.navigationItems, data.userInfo.organisationId, CacheKeys.TopMenu)
				)
			);
		}
	}

	sanitizeRoutes(resp: NavigationItem[], organisationId: string, filter: string): NavigationItem[] {
		//-- filter navtion items e.g tiles / userMen / TopMenu
		resp = resp.filter((element) => element.type == filter);

		//-- map routeKey from Routes class to get actual route
		resp.forEach((item) => (item.routeKey = AppRoutes[item.routeKey as keyof AppRoutes]));

		//-- replace organisation Id variable to user's organisation id
		resp.forEach((item) => (item.routeKey = item.routeKey.replace('{organisationId}', organisationId)));

		return resp;
	}

	errorHandler(err: any): Observable<any> {
		if (err.status == 403) {
			//this.messageService.isLoggedIn(false);
			this.clearSessionStorage();
			//this.messageService.showWarning('Refresh Token Expired');
		} else {
			if (err.error) {
				if (err.error.errors) this.messageService.showError(err.error.errors);
				else this.messageService.showError(err.error);
			} else this.messageService.showError('Something went wrong.');
		}

		return of({});
	}

	public tokenExpired() {
		let token: string = sessionStorage.getItem(CacheKeys.Token) ?? '';
		if (token.length > 0) {
			const expiry = JSON.parse(atob(token.split('.')[1])).exp;
			return Math.floor(new Date().getTime() / 1000) >= expiry;
		} else {
			return true;
		}
	}

	public newUserInstance() {
		return {
			userName: '',
			firstName: '',
			lastName: '',
			email: '',
			fullName: '',
			jobTitleInd: 0,
			jobTitleOther: '',
			locked: false,
			role: '',
			lockedMsg: '',
			organisationId: 0,
			organisationPlatform: 'NID',
			organisationCountry: '',
		};
	}
}
